@font-face {
  font-family: 'Avenir';
  font-weight: 300;
  font-style: oblique;
  src: url('./assets/Avenir-LightOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  src: url('./assets/Avenir-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  src: url('./assets/Avenir-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Zapfino';
  src: url('./assets/Zapfino.ttf') format('truetype');
}

html, body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10.3vw;
  padding-right: 10.3vw;
  overflow-x: hidden;
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #333;
  z-index: 10000;
}

.navLink {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 0.9vw;
  color: #333;
  opacity: 0.4;
  margin-left: 10vw;
  cursor: pointer;
  z-index: 500;
  transition: all 0.5s ease-out;
}

.navLink:hover {
  opacity: 1;
}

.firstLink {
  margin-left: 10.3vw;
}

.heroBg {
  width: 100vw;
  height: 100vh;
  background-color: rgba(250, 223, 217, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.heroContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
}

.firstHeading {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1vw;
  color: #333;
  align-self: flex-end;
  margin-right: 8vw;
}

.heroName {
  font-family: 'Zapfino';
  font-size: 7.5vw;
  color: #333;
  margin-left: -7.8vw;
  margin-bottom: 0;
  margin-top: 0;
  z-index: 100;
}

.mobileHeroName {
  display: none;
}

.subheading {
  font-family: 'Avenir';
  font-weight: 400;
  color: rgba(51,51,51,1);
  font-size: 1.3vw;
  line-height: 2vw;
  align-self: flex-start;
  width: 42%;
  margin-top: -10vh;
  margin-bottom: 0;
}

.callToAction {
  font-family: 'Avenir';
  font-weight: 400;
  color: #ffffff;
  background-color: #333;
  font-size: 1vw;
  align-self: flex-start;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-right: 5vw;
  padding-left: 5vw;
  border-radius: 40px;
  margin-top: 8vh;
  border: none;
  cursor: pointer;
  z-index: 70;
  -webkit-box-shadow: 0px 20px 50px -10px rgba(51,51,51,1);
  -moz-box-shadow: 0px 20px 50px -10px rgba(51,51,51,1);
  box-shadow: 0px 20px 50px -10px rgba(51,51,51,1);
  transition: all 0.2s ease-out;
}

.callToAction:hover {
  background-color: rgba(51,51,51,0.95);
  color: rgba(255,255,255,0.95);
}

.imgContainer {
  height: 100vh;
  width: 50vw;
  position: absolute;
  top: 0;
  right: 0;
  overflow-x: hidden;
}

.computerImg {
  height: 90vh;
  width: auto;
  position: absolute;
  top: -17vh;
  right: -35vh;
  z-index: 50;
  transform: rotate(20deg);
}

.mugImgContainer {
  display: none;
}

.mugImg {
  height: auto;
  width: 17vw;
  position: absolute;
  top: 60vh;
  right: 5vw;
  z-index: 100;
  transform: rotate(10deg);
}

.notepadImg {
  height: auto;
  width: 30vw;
  position: absolute;
  top: 70vh;
  right: 25vw;
  z-index: 100;
}

.goalsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 60vh;
  position: relative;
}

.goalsBg {
  width: 100vw;
  height: 120%;
  position: absolute;
  left: -10.3vw;
  top: 50%;
  transform: translate(0%, -50%);
}

.goalsFirst {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.goalContainer {
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goalText {
  font-size: 1.3vw;
  font-family: 'Avenir';
  font-weight: 700;
  color: #FADFD9;
  text-align: center;
  width: 90%;
}

.goalsSecond {
  width: calc(50% + 10vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.goalContainerAlt {
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  background-color: #FADFD9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goalTextAlt {
  font-size: 1.3vw;
  font-family: 'Avenir';
  font-weight: 700;
  color: #333;
  text-align: center;
  width: 90%;
}

.servicesContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30vh;
  position: relative;
  z-index: 500000;
}

.serviceFrame {
  width: 22%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-out;
}

.serviceFrame:hover {
  transform: translate(-4px, -4px);
  -webkit-box-shadow: -5px 5px 7px 0px rgba(51,51,51,0.5);
  -moz-box-shadow: -5px 5px 7px 0px rgba(51,51,51,0.5);
  box-shadow: -5px 5px 7px 0px rgba(51,51,51,0.5);
  border: 0.5px solid rgba(51,51,51,0.2);
}

.serviceImg {
  width: 70%;
  height: auto;
  margin-bottom: 1vh;
  margin-top: 3vh;
}

.serviceTitle {
  font-family: 'Avenir';
  font-weight: 400;
  color: #333333;
  font-size: 2vw;
  width: 75%;
  margin-bottom: 1vh;
}

.serviceText {
  font-family: 'Avenir';
  font-weight: 400;
  color: rgba(51,51,51,0.95);
  font-size: 1vw;
  line-height: 1.7vw;
  width: 75%;
  text-align: justify;
  margin-bottom: 3vh;
}

.clientsOuter {
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15vh 0;
}

.clientsTitle {
  font-family: 'Avenir';
  font-weight: 700;
  color: #333333;
  font-size: 5vw;
  margin-bottom: 20vh;
  position: relative;
}

.clientsTitleSemiUnderline {
  width: 30%;
  position: absolute;
  bottom: -30%;
  left: 50%;
  transform: translate(-50%, 0);
  border-bottom: 10px solid #FADFD9;
  border-radius: 100px;
}

.clientsContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.titleContainer {
  width: 20%;
  height: auto;
}

.clientsMain {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.clientsRow {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 5vh;
}

.clientSingle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 15vw;
  height: auto;
  cursor: pointer;
  margin-right: 1.5vw;
  margin-left: 1.5vw;
}

.clientSingleImg {
  width: 10vw;
  height: auto;
  margin-bottom: 3vh;
  transition: all 0.5s ease-out;
}

.clientSingleImg:hover {
  opacity: 0.7;
}

.clientSingleTitle {
  font-family: 'Avenir';
  font-weight: 700;
  color: #333333;
  font-size: 1.3vw;
  text-align: center;
  margin-bottom: 1vh;
  margin-top: 0;
}

.clientSingleHandle {
  font-family: 'Avenir';
  font-weight: 400;
  color: #333333;
  font-size: 1.3vw;
  margin-bottom: 0;
  margin-top: 0;
}

.aboutContainer {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20vh;
}

.aboutImg {
  width: 50vw;
  height: 100vh;
  object-fit: cover;
}

.aboutTextContainer {
  height: 100vh;
  width: 50vw;
  align-self: flex-end;
  background-color: rgba(250, 223, 217, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.aboutTitle {
  font-family: 'Avenir';
  font-weight: 300;
  font-style: oblique;
  color: #333333;
  font-size: 3vw;
  margin-bottom: 3vh;
  margin-right: 10.3vw;
}

.aboutText {
  font-family: 'Avenir';
  font-weight: 400;
  color: #333333;
  font-size: 1vw;
  line-height: 2vw;
  width: 60%;
  text-align: justify;
  margin-right: 10.3vw;
}

.contactOuterContainer {
  background-color: rgba(250, 223, 217, 0.7);
  width: 100vw;
  height: auto;
  position: relative;
}

.contactImg {
  width: 20vw;
  height: auto;
  position: absolute;
  top: 0;
  right: 20vw;
  transform: rotate(15deg);
  -webkit-box-shadow: -2px 2px 10px 0 rgba(51,51,51,0.5);
  -moz-box-shadow: -2px 2px 10px 0 rgba(51,51,51,0.5);
  box-shadow: -2px 2px 10px 0 rgba(51,51,51,0.5);
}

.contactContainer {
  width: calc(100% - 20.6vw);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10.3vw;
  margin-left: 10.3vw;
  margin-bottom: 10vh;
}

.formContainer {
  width: 35%;
  height: auto;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 10;
}

.formAux {
  color: #333333;
  font-family: 'Zapfino';
  font-size: 2.5vw;
  margin-top: 0;
  margin-bottom: 0;
}

.formSubText {
  color: #333333;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.3vw;
  margin-top: -2vh;
  margin-bottom: 5vh;
}

.formInputLabel {
  color: #333333;
  font-family: 'Avenir';
  font-weight: 700;
  font-size: 1vw;
  margin-top: 0;
  margin-bottom: 1.5vh;
}

.formInputSimple {
  color: rgba(51, 51, 51, 1);
  background-color: #fff;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 3.2vw;
  width: 100%;
  border-radius: 20px;
  padding-left: 1vw;
  padding-right: 1vw;
  border: 0.5px solid rgba(51,51,51,0.2);
}

.formInputSimple::placeholder {
  color: rgba(51, 51, 51, 0.7);
}

.formInputSimple:focus {
  outline: none;
}

.formTextArea {
  color: #333333;
  background-color: #fff;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  margin-top: 0;
  margin-bottom: 2vh;
  height: 25vh;
  width: 100%;
  border-radius: 20px;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0.5px solid rgba(51,51,51,0.2);
  resize: none;
}

.formTextArea::placeholder {
  color: rgba(51, 51, 51, 0.7);
}

.formTextArea:focus {
  outline: none;
}

.formButton {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 35px;
  background-color: #333333;
  color: #fff;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1vw;
  border: none;
  border-radius: 30px;
  margin-top: 2vh;
  cursor: pointer;
  margin-right: -2vw;
}

.formButton:hover {
  opacity: 0.9;
}

.formButton:focus {
  outline: none;
}

.formButton:disabled {
  cursor: default;
  opacity: 0.6;
}

.sendEmailContainer {
  width: 45%;
  height: 55vh;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 10;
}

.sendEmailAux {
  color: #333333;
  font-family: 'Zapfino';
  font-size: 1.5vw;
  margin-top: 0;
  margin-bottom: 0;
}

.sendEmailAddress {
  color: #333333;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 2.5vw;
  margin-top: -1.5vh;
  margin-bottom: 0;
}

.footer {
  width: 100vw;
  height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  z-index: 100000;
}

.footerText {
  color: #fff;
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1vw;
  margin-top: 0;
  margin-bottom: 0;
}

.footerLeft {
  margin-left: 10.3vw;
}

.footerRight {
  margin-right: 10.3vw;
}

.logoFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: auto;
}

.logoFooter {
  height: 40%;
  width: auto;
  margin-left: 0.3vw;
  cursor: pointer;
  margin-bottom: 0.5vh;
}

@media (orientation: portrait) and (min-width: 768px) and (max-width: 1024px) {
  
  .heroBg {
    height: 50vh;
  }
  
  .heroContainer {
    margin-top: 10vh;
  }

  .navbar {
    height: 5vh;
  }

  .navLink {
    font-size: 0.9vh;
  }
  
  .firstHeading {
    font-size: 1vw;
    margin-right: 15vw;
  }
  
  .subheading {
    font-size: 1.8vw;
    line-height: 2.7vw;
    width: 50%;
    margin-top: -4vh;
  }
  
  .callToAction {
    font-size: 1.3vw;
    padding-top: 1.7vh;
    padding-bottom: 1.7vh;
    padding-right: 6vw;
    padding-left: 6vw;
    border-radius: 40px;
    margin-top: 3vh;
  }
  
  .imgContainer {
    height: 100vh;
    width: 50vw;
    position: absolute;
    top: 0;
    right: 0;
    overflow-x: hidden;
  }
  
  .computerImg {
    height: 65vh;
    top: -15vh;
    right: -25vh;
    transform: rotate(15deg);
  }
  
  .mugImg {
    width: 20vw;
    top: 32vh;
    right: 4vw;
  }
  
  .notepadImg {
    width: 34vw;
    top: 34vh;
    right: 23vw;
  }

  .goalsContainer {
    margin-top: 25vh;
  }
  
  .servicesContainer {
    margin-top: 10vh;
  }

  .serviceFrame {
    transition: none;
  }
  
  .serviceFrame:hover {
    transform: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
  }
  
  .serviceTitle {
    font-size: 2vw;
  }
  
  .serviceText {
    font-size: 1.2vw;
    line-height: 1.7vw;
  }

  .clientsOuter {
    width: 90vw;
    margin: 6vh 0;
  }
  
  .clientsTitle {
    font-size: 5vw;
    margin-bottom: 10vh;
  }
  
  .clientsTitleSemiUnderline {
    width: 30%;
    position: absolute;
    bottom: -50%;
    border-bottom: 6px solid #FADFD9;
    border-radius: 100px;
  }

  .clientsContainer {
    margin-top: 0;
  }
  
  .clientsRow {
    margin-bottom: 4vh;
  }
  
  .clientSingleImg {
    margin-bottom: 1vh;
  }
  
  .clientSingleTitle {
    margin-bottom: 0.5vh;
  }

  .aboutContainer {
    margin-top: 10vh;
  }
  
  .aboutImg {
    width: 50vw;
    height: 50vh;
    object-fit: cover;
    object-position: 60%;
  }
  
  .aboutTextContainer {
    height: 50vh;
  }
  
  .aboutTitle {
    font-size: 4vw;
    margin-bottom: 2vh;
  }
  
  .aboutText {
    font-size: 1.2vw;
    line-height: 2.4vw;
  }
  
  .contactImg {
    width: 30vw;
    top: 0;
    right: 13vw;
  }

  .contactContainer {
    margin-bottom: 6vh;
  }
  
  .formContainer {
    width: 40%;
    margin-top: 6vh;
  }
  
  .formAux {
    font-size: 3vw;
  }

  .formSubText {
    margin-top: -1vh;
    margin-bottom: 2vh;
    font-size: 1.5vw;
  }
  
  .formInputLabel {
    font-size: 1.5vw;
  }
  
  .formInputSimple {
    font-size: 1.5vw;
    line-height: 3vw;
    height: 4vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }
  
  .formTextArea {
    font-size: 1.5vw;
    line-height: 3vw;
    height: 17vh;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
  }

  .formButton {
    font-size: 1.5vw;
    margin-top: 1vh;
    margin-right: -3vw;
  }
  
  .sendEmailContainer {
    height: 35vh;
    margin-top: 10vh;
  }
  
  .sendEmailAux {
    font-size: 2vw;
  }
  
  .sendEmailAddress {
    font-size: 3vw;
    margin-top: -0.5vh;
  }
  
  .footer {
    height: 5vh;
  }
  
  .footerText {
    font-size: 1.5vw;
    width: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .logoFooter {
    margin-left: 0.3vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .App {
    padding-left: 8.3vw;
    padding-right: 8.3vw;
  }

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 84.4vw;
    margin-left: 8.3vw;
    margin-right: 8.3vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
  }
  
  .navLink {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3vw;
    color: #333;
    opacity: 0.8;
    margin-left: 0;
    cursor: pointer;
    z-index: 500;
    transition: all 0.5s ease-out;
  }
  
  .navLink:hover {
    opacity: 1;
  }
  
  .firstLink {
    margin-left: 0;
  }

  .heroContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15vh;
  }

  .firstHeading {
    display: none;
  }
  
  .subheading {
    font-family: 'Avenir';
    font-weight: 700;
    color: rgba(51,51,51,1);
    font-size: 4.5vw;
    line-height: 7vw;
    align-self: flex-start;
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 0;
  }
  
  .callToAction {
    font-family: 'Avenir';
    font-weight: 400;
    color: #ffffff;
    background-color: #333;
    font-size: 4vw;
    align-self: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-right: 12vw;
    padding-left: 12vw;
    border-radius: 40px;
    margin-top: 8vh;
    border: none;
    cursor: pointer;
    z-index: 100000;
    -webkit-box-shadow: 0px 20px 50px -10px rgba(51,51,51,1);
    -moz-box-shadow: 0px 20px 50px -10px rgba(51,51,51,1);
    box-shadow: 0px 20px 50px -10px rgba(51,51,51,1);
    transition: all 0.2s ease-out;
  }
  
  .callToAction:hover {
    opacity: 0.95;
  }

  .heroName {
    display: none;
  }

  .mobileHeroName {
    display: flex;
    font-family: 'Zapfino';
    font-size: 11vw;
    color: #333;
    margin-bottom: 0;
    margin-top: 0;
    z-index: 100;
  }

  .leftHero {
    align-self: flex-start;
  }

  .rightHero {
    align-self: flex-end;
    margin-right: 6vw;
    margin-top: -7vh;
  }

  .mugImgContainer {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .mobileMugImg {
    height: auto;
    width: 28vw;
    position: absolute;
    top: 40vh;
    right: -10vw;
    transform: rotate(10deg);
  }

  .mugImg {
    display: none;
  }
  
  .notepadImg {
    height: auto;
    width: 60vw;
    position: absolute;
    top: 95vh;
    left: -7vw;
    z-index: 100;
  }
  
  .computerImg {
    display: none;
  }

  .goalsContainer {
    margin-top: 50vh;
  }

  .goalsBg {
    display: none;
  }
  
  .goalsFirst {
    flex-direction: column;
  }
  
  .goalContainer {
    width: 75vw;
    height: 75vw;
    margin-top: 5vh;
  }
  
  .goalText {
    font-size: 5vw;
  }
  
  .goalsSecond {
    width: 100%;
    flex-direction: column;
  }
  
  .goalContainerAlt {
    width: 75vw;
    height: 75vw;
    background-color: #333;
    margin-top: 5vh;
  }
  
  .goalTextAlt {
    font-size: 5vw;
    color: #FADFD9;
  }

  .mobileGoal {
    background-color: #FADFD9;
  }

  .mobileGoalText {
    color: #333;
  }

  .servicesContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20vh;
  }
  
  .serviceFrame {
    width: 75%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: none;
    margin-bottom: 5vh;
  }

  .serviceFrame:hover {
    transform: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
  }
  
  .serviceImg {
    width: 70%;
    height: auto;
    margin-bottom: 0.7vh;
    margin-top: 2vh;
  }
  
  .serviceTitle {
    font-family: 'Avenir';
    font-weight: 400;
    color: #333333;
    font-size: 7vw;
    width: 75%;
    margin-bottom: 0.7vh;
  }
  
  .serviceText {
    font-family: 'Avenir';
    font-weight: 400;
    color: rgba(51,51,51,0.95);
    font-size: 4vw;
    line-height: 6.3vw;
    width: 75%;
    text-align: justify;
    margin-bottom: 6vh;
  }

  .clientsOuter {
    width: 90vw;
    margin: 10vh 0;
  }
  
  .clientsTitle {
    font-size: 10vw;
    margin-bottom: 10vh;
  }
  
  .clientsTitleSemiUnderline {
    width: 30%;
    position: absolute;
    bottom: -50%;
    border-bottom: 6px solid #FADFD9;
    border-radius: 100px;
  }

  .clientsContainer {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .titleContainer {
    width: 70%;
    height: auto;
    margin-bottom: 4vh;
  }
  
  .clientsMain {
    width: 100%;
  }
  
  .clientsRow {
    margin-top: 8vh;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }
  
  .clientSingle {
    width: auto;
    margin-bottom: 8vh;
  }
  
  .clientSingleImg {
    width: 50vw;
    height: auto;
    margin-bottom: 5vh;
    transition: all 0.5s ease-out;
  }
  
  .clientSingleImg:hover {
    opacity: 1;
  }
  
  .clientSingleTitle {
    font-size: 6vw;
    margin-bottom: 0.7vh;
  }
  
  .clientSingleHandle {
    font-size: 5vw;
  }

  .aboutContainer {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20vh;
    position: relative;
  }
  
  .aboutImg {
    position: absolute;
    top: -15vh;
    left: 0;
    width: 80vw;
    height: 50vh;
    object-fit: cover;
    object-position: 70%;
  }
  
  .aboutTextContainer {
    height: 120vh;
    width: 100vw;
    align-self: flex-end;
    background-color: rgba(250, 223, 217, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .aboutTitle {
    font-family: 'Avenir';
    font-weight: 300;
    font-style: oblique;
    color: #333333;
    font-size: 10vw;
    margin-bottom: 3vh;
    margin-right: 10.3vw;
  }
  
  .aboutText {
    font-family: 'Avenir';
    font-weight: 400;
    color: #333333;
    font-size: 3.5vw;
    line-height: 7vw;
    width: 84.4vw;
    text-align: justify;
    margin-right: 8.3vw;
    margin-left: 8.3vw;
    margin-bottom: 15vh;
  }

  .contactOuterContainer {
    background-color: rgba(250, 223, 217, 0.7);
    width: 100vw;
    height: auto;
    position: relative;
  }
  
  .contactImg {
    width: 45vw;
    height: auto;
    position: absolute;
    top: -8vh;
    right: -3vw;
    transform: rotate(15deg), translate(-50%);
    -webkit-box-shadow: -2px 2px 10px 0 rgba(51,51,51,0.5);
    -moz-box-shadow: -2px 2px 10px 0 rgba(51,51,51,0.5);
    box-shadow: -2px 2px 10px 0 rgba(51,51,51,0.5);
  }
  
  .contactContainer {
    width: 84.4vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 18vh;
    margin-right: 8.3vw;
    margin-left: 8.3vw;
    margin-bottom: 10vh;
  }
  
  .formContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 10;
  }
  
  .formAux {
    color: #333333;
    font-family: 'Zapfino';
    font-size: 7vw;
    margin-top: 0;
    margin-bottom: 0;
  }

  .formSubText {
    margin-top: -1vh;
    margin-bottom: 3vh;
    font-size: 4vw;
  }
  
  .formInputLabel {
    color: #333333;
    font-family: 'Avenir';
    font-weight: 700;
    font-size: 3.5vw;
    margin-top: 0;
    margin-bottom: 1.5vh;
  }
  
  .formInputSimple {
    color: rgba(51, 51, 51, 1);
    background-color: #fff;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3.5vw;
    line-height: 7vw;
    margin-top: 0;
    margin-bottom: 2vh;
    height: 8.3vw;
    width: calc(100% - 6vw);
    border-radius: 20px;
    padding-left: 3vw;
    padding-right: 3vw;
    border: 0.5px solid rgba(51,51,51,0.2);
  }
  
  .formInputSimple::placeholder {
    color: rgba(51, 51, 51, 0.7);
  }
  
  .formInputSimple:focus {
    outline: none;
  }
  
  .formTextArea {
    color: #333333;
    background-color: #fff;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3.5vw;
    line-height: 7vw;
    margin-top: 0;
    margin-bottom: 2vh;
    height: 30vh;
    width: calc(100% - 6vw);
    border-radius: 20px;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.5px solid rgba(51,51,51,0.2);
    resize: none;
  }
  
  .formTextArea::placeholder {
    color: rgba(51, 51, 51, 0.7);
  }
  
  .formTextArea:focus {
    outline: none;
  }
  
  .formButton {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 35px;
    background-color: #333333;
    color: #fff;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 3.5vw;
    border: none;
    border-radius: 30px;
    margin-top: 2vh;
    cursor: pointer;
    margin-right: 0;
  }
  
  .formButton:hover {
    opacity: 0.9;
  }
  
  .formButton:focus {
    outline: none;
  }
  
  .formButton:disabled {
    cursor: default;
    opacity: 0.6;
  }
  
  .sendEmailContainer {
    width: 100%;
    height: 15vh;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;
  }
  
  .sendEmailAux {
    color: #333333;
    font-family: 'Zapfino';
    font-size: 5vw;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .sendEmailAddress {
    color: #333333;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 7vw;
    margin-top: 0vh;
    margin-bottom: 0;
  }

  .footer {
    width: 100vw;
    height: 8vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    z-index: 100000;
  }
  
  .footerText {
    color: #fff;
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 4vw;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
  }

  .footerLeft {
    margin-left: 8.3vw;
  }
  
  .footerRight {
    margin-right: 8.3vw;
  }

  .logoFooter {
    margin-left: 0.5vh;
  }

  .designedBy {
    display: none;
  }
}